*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.background {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}
.loginLogo {
  width: 330px;
}
.loginForm {
  width: 400px;
  background-color: #ffffff;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 0px solid rgba(0, 0, 0, 255);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, .4);
  padding: 35px 35px;
}
.loginForm * {
  font-family: "Poppins", sans-serif;
  color: #4D330C;
  letter-spacing: 1.5px;
}
form h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

.loginLabel {
  display: block;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
  text-align: start;
}
.loginError {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  color: #a63232;
  font-weight: 500;
  text-align: start;
}
.loginInput {
  display: block;
  height: 50px;
  width: 100%;
  font-family: "Poppins", sans-serif;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 0 20px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
  outline: none;
  border: 0px;
}
::placeholder {
  color: #333;
}
.loginButton {
  margin-top: 25px;
  width: 100%;
  background-color: #4D330C;
  color: #fff;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border: 0px;
  border-radius: 8px;
  cursor: pointer;
}
